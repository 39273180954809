// serviceWorker supporter
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register(url+'/sw_cached_pages.js')
      .then(() => '')
      .catch(err => console.log(`Service worker: Error: ${err}`));
  })
}



let deferredPrompt;

var showInstallBanner = document.getElementById("show-install-banner");
var installBtn = document.getElementById("install-app-now");

if(showInstallBanner !== undefined){
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt = e;
    showInstallBanner.style.cssText += ';display:block !important;'
  });

  installBtn.addEventListener('click', () => {

    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choice) => {
        if (choice.outcome === 'accepted') {
            //console.log('User installed app');
        }
    });
  });
}

window.addEventListener('appinstalled', (evt) => {
  var modalApp = document.getElementById('install-app-banner').getElementsByClassName('modal-content')[0];
  modalApp.innerHTML = '<div class="modal-body pt-5 pb-5"><p class="mb-0"></p><p class="mb-0 font-2">&iexcl;App instalada con &eacute;xito!</p><p>Record&aacute; que siempre pod&eacute;s usar Don Potrero, aunque no tengas tu dispositivo a mano.</p><p>A jugar y seguir escribiendo tu historia.</p><button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar</button></div>';
});



var url = document.getElementsByName('base_url')[0].getAttribute('content')


var mainLink;
var clickableElements;
var cardClickable = document.querySelectorAll(".clickable-card");
if (cardClickable.length > 0) {

  for(var i = 0; i < cardClickable.length; i++) {
    (function(index) {
      clickableElements = Array.from(cardClickable[index].querySelector("a"));
      clickableElements.forEach((ele) =>
        ele.addEventListener("click", (e) => e.stopPropagation())
      );
      cardClickable[index].addEventListener("click", function(){
        var noTextSelected = !window.getSelection().toString();
        if (noTextSelected) {
          var mainLink = cardClickable[index].querySelector('.main-link');
          mainLink.click();
        }
      });
    })(i);
  }

}

function togglePassword(button) {
  var parent = button.parentNode.parentNode;
  var input = parent.querySelector('input');
  if (input.type === "password") {
    input.type = "text";
    button.innerHTML = '<i class="icon-don icon-visibility-off text-danger"></i>';
  } else {
    input.type = "password";
    button.innerHTML = '<i class="icon-don icon-visibility-on"></i>';
  }
}

$(document).ready(function () {
    if ($('#myModal #delete').length > 0) {
        $('#myModal #delete').click(function() {
            $.ajaxPrefilter(function(options, originalOptions, xhr) {
                var token = $('meta[name="csrf-token"]').attr("content");

                if (token) {
                    return xhr.setRequestHeader('X-CSRF-TOKEN', token);
                }
            });

            $.post($(this).data("post-url"), function (response) {
                window.location.href= $(this).data("redirect-url");
            }.bind(this))
        });
    }


    var url = $('meta[name="base_url"]').attr('content');

    $('a[href="#"]').on('click', function (e) {
        e.preventDefault();
    });

    $('.alert-don .close-x').on('click', function(){
        $(this).closest('.alert-don').remove();
    })

    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    })

    $('.copyClipboard').on('click', function(){
        if($(this).is('input')){
            var copyText = $(this);
            copyText.select();
            document.execCommand("copy");
        } else {
            var copyText = $(this).attr('data-clipboard');
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val(copyText).select();
            document.execCommand("copy");
            $temp.remove();
        }
        $(this).find('small').show().delay(750).fadeOut(300);
        window.getSelection().removeAllRanges();
    });

});

